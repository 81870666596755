.HomePageWrapper {
  width: 100vw;
  height: calc(100vh - 65px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #91938822;
}
.HomePageContainer {
  --width: min(min(100vw, calc(calc(100vh - 65px) / 0.6)), 16000px);
  width: var(--width);
  height: calc(var(--width) * 0.6);
  /* background-color: red; */
  box-sizing: border-box;
}
.HomePageController {
  width: var(--width);
  height: calc(var(--width) * 0.6);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  /* background: #91938822; */
  /* box-shadow: 0 0 3px  rebeccapurple; */
}

.DrawingContainer {
  width: calc(var(--width) * 0.9);
  height: calc(var(--width) * 0.2);

  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
}

.DrawingContainer > * {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}

.IconsContainer {
  z-index: 1;
  width: calc(var(--width) * 0.9);
  height: calc(var(--width) * 0.2);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Drawing {
  width: 100%;
  padding: 2.5% 1% 0;
  box-sizing: border-box;
  z-index: 2;
  pointer-events: none;
}

.IconsContainer .Icon {
  background: white;

  z-index: 1;
  width: calc(var(--width) * 0.2);
  height: calc(var(--width) * 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: calc(var(--width) * 0.01);
  transition: all 250ms;

  box-sizing: border-box;
  cursor: pointer;
}

.IconsContainer .Icon h1 {
  font-family: neuzeit-grotesk, sans-serif;
  font-size: calc(var(--width) * 0.025);
  font-weight: 400;
  margin: 0;
  line-height: normal;
  color: #919388;
}

.IconsContainer .Icon.Grass {
  border: calc(var(--width) * 0.004) solid #78be2099;
}

.IconsContainer .Icon.Grass.Enabled h1 {
  color: #78be20;
  font-weight: 700;
}

.IconsContainer .Icon.Weighing {
  border: calc(var(--width) * 0.004) solid #f2a90099;
}

.IconsContainer .Icon.Weighing.Enabled h1 {
  color: #f2a900;
  font-weight: 700;
}

.IconsContainer .Icon.Growth {
  border: calc(var(--width) * 0.004) solid #00b2de99;
}

.IconsContainer .Icon.Environment {
  border: calc(var(--width) * 0.004) solid #00b2de99;
}

.IconsContainer .Icon.Growth.Enabled h1 {
  color: #00b2de;
  font-weight: 700;
}

.IconsContainer .Icon.Environment.Enabled h1 {
  color: #00b2de;
  font-weight: 700;
}

.Graphic {
  width: 80%;
  box-sizing: border-box;
}

.DescriptionContainer {
  font-family: neuzeit-grotesk, sans-serif;
  z-index: 1;
  width: calc(var(--width) * 0.9);
  height: calc(var(--width) * 0.3);
  margin-top: calc(var(--width) * 0.02);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.DescriptionContainer .Icon.Description {
  width: calc(var(--width) * 0.16);
  height: 100%;
  box-sizing: border-box;
  border-radius: calc(var(--width) * 0.01);
  background-color: white;
  padding: calc(var(--width) * 0.01);
  margin: calc(var(--width) * 0.02);
  line-height: normal;
  display: flex;
  flex-direction: column;

  align-items: stretch;
  justify-content: space-between;
}

.DescriptionContainer .Icon.Description h1 {
  font-size: calc(var(--width) * 0.016);
  margin: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: calc(var(--width) * 0.035);
}

.DescriptionContainer .Icon.Description .FieldIcon {
  height: calc(var(--width) * 0.017);
}

.DescriptionContainer .Icon.Description h2 {
  font-size: calc(var(--width) * 0.015);
  margin: 0;
  font-style: italic;
  font-weight: 400;
  line-height: calc(var(--width) * 0.016);
}
.DescriptionContainer .Icon.Description ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.DescriptionContainer .Icon.Description li {
  font-size: calc(var(--width) * 0.014);
  margin: 0;
  font-weight: 400;
  line-height: calc(var(--width) * 0.016);
  letter-spacing: 0;
}

.DescriptionContainer .Icon.Grass {
  border: calc(var(--width) * 0.001) solid #78be2099;
  /* background: hsl(87, 71%, 90%); */
}

.DescriptionContainer .Icon.Weighing {
  border: calc(var(--width) * 0.001) solid #f2a90099;
  /* background: hsl(42, 100%, 92%); */
}

.DescriptionContainer .Icon.Growth {
  border: calc(var(--width) * 0.001) solid #00b2de99;
  /* background: hsl(192, 100%, 90%); */
}

.DescriptionContainer .Icon.Environment {
  border: calc(var(--width) * 0.001) solid #00b2de99;
  /* background: hsl(192, 100%, 90%); */
}

.DescriptionContainer .ButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.DescriptionContainer .ButtonContainer .Button {
  width: calc(var(--width) * 0.086);
  height: calc(var(--width) * 0.03);
  border-radius: calc(var(--width) * 0.005);
  background: white;
  color: #51534a;
  font-weight: 600;
  font-size: calc(var(--width) * 0.013);
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0;
  transition: all 250ms;
}

.DescriptionContainer .ButtonContainer .Button.Grass {
  border: calc(var(--width) * 0.001) solid #78be2099;
}
.DescriptionContainer .ButtonContainer .Button.Grass:hover {
  background: #78be2099;
}
.DescriptionContainer .ButtonContainer .Button.Weighing {
  border: calc(var(--width) * 0.001) solid #f2a90099;
}
.DescriptionContainer .ButtonContainer .Button.Weighing:hover {
  background: #f2a90099;
}
.DescriptionContainer .ButtonContainer .Button.Growth {
  border: calc(var(--width) * 0.001) solid #00b2de99;
}
.DescriptionContainer .ButtonContainer .Button.Growth:hover {
  background: #00b2de99;
}
.DescriptionContainer .ButtonContainer .Button.Environment {
  border: calc(var(--width) * 0.001) solid #00b2de99;
}
.DescriptionContainer .ButtonContainer .Button.Environment:hover {
  background: #00b2de99;
}

.DescriptionContainer .ButtonContainer .Button.Full {
  width: 100%;
}

.HomePageFarmBrowser {
  font-family: neuzeit-grotesk, sans-serif;
  z-index: 1;
  width: calc(var(--width));
  height: calc(var(--width) * 0.3);
  margin-top: calc(var(--width) * 0.02);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.HomePageFarmBrowser .BrowserIconContainer {
  width: 100%;
  height: calc(var(--width) * 0.3);
  /* padding: 5%; */
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.HomePageFarmBrowser .BrowserIconContainer .BrowserIconRow {
  width: calc(var(--width) * 0.9);
  height: calc(var(--width) * 0.3);
  /* padding: 5%; */
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.HomePageFarmBrowser .BrowserIconContainer .FarmBrowserNav {
  font-family: neuzeit-grotesk, sans-serif;
  color: #51534a;
  font-weight: 600;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(var(--width) * 0.03);
  width: calc(var(--width) * 0.05);
}
