.WeighingSessionSelector {
  margin: 8px 12px;
  padding: 8px 16px;
  background-color: #51534a;
  color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  font-size: medium;
  line-height: normal;
}

.WeighingSessionSelector.Selected {
  background-color: #78be20;
  font-weight: bold;
}

.ProportionLineContainer {
  margin: 8px 12px;
  /* height: 60px; */
  display: flex;
  flex-direction: row;
}

.Proportion {
  width: 3px;
}

.ProportionLabel {
  height: 20px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

/* .ProportionLabel span {
  padding: 0 8px;
}

.Proportion:first-child .ProportionLabel {
  justify-content: start;
}
.Proportion:last-child .ProportionLabel {
  justify-content: end;
}
.Proportion:only-child .ProportionLabel {
  justify-content: center;
} */

.ProportionLine {
  height: 30px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
}

/* .ProportionLine span {
  padding: 0 8px;
} */

.Proportion:first-child .ProportionLine {
  border-radius: 6px 0 0 6px;
  /* justify-content: start; */
}
.Proportion:last-child .ProportionLine {
  border-radius: 0 6px 6px 0;
  /* justify-content: end; */
}
.Proportion:only-child .ProportionLine {
  border-radius: 6px;
  /* justify-content: center; */
}

.TagButtonContainer {
  border: 1px solid#f2ba00;
  border-radius: 8px;
  margin: 8px;
  overflow: hidden;
}

.TagButton {
  background-color: #f2ba0033;
  /* color: white; */
  font-size: larger;
  /* border-radius: 7px; */
  padding: 8px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: medium;
  line-height: normal;
  font-weight: bold;
  /* transition: all 250ms; */
}
.TagButton.Filtered {
  background-color: #f2ba00;
}

/* .TagButton.Expanded {
  border-radius: 8px 8px 0 0;
} */

.TagButton span {
  margin: 0 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: medium;
  line-height: normal;
  font-weight: bold;
}

.TagButton span:last-child {
  font-size: 1.1em;
}

.AnimalDetails {
  padding: 0 4px 8px;
}

.AnimalDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
  height: 0;
  overflow-y: hidden;
  transition: all 250ms;
}

.AnimalDetails.Show {
  height: 180px;
  padding: 0 4px 8px;
}

.AnimalDetailsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px 8px;
  font-size: larger;
}

.WeightRightPanel {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.HalfWidth {
  width: 400px;
  height: calc((100vh - 65px));
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
