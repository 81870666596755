.MapTableContainer {
  width: 100%;
  height: calc(100%);
  overflow-y: auto;
  float: right;
  box-sizing: border-box;
}

.MapTable {
  font-size: 14px;
  text-align: center;
  border-collapse: collapse;
  width: 100%;
  float: right;
  font-weight: bold;
}

.MapTable th {
  background: rgba(81, 83, 74, 0.8);
  color: white;
}

.MapTable tbody tr th {
  color: white;
  font-weight: bold;
  letter-spacing: 0.3px;
  margin: 2px 0px 2px 0px;
}

.MapTable tr:nth-child(odd) td {
  background: white;
  color: #51534a;
}

.MapTable tr:nth-child(even) td {
  background: white;
  color: #51534a;
}

.MapTable tr th {
  padding: 3px;
  width: 33%;
  font-weight: bold;
}

.MapTable tr td {
  padding: 4px;
  font-weight: normal;
  cursor: pointer;
}

.MapTable tr.active td {
  background: #51534a;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.MapTable tr th.MapTableHeader {
  font-size: 18px;
  padding: 3px;
  font-weight: bold;
}

.MapButtonContainer {
  display: flex;
}

.MapButtonAddContainer {
  position: relative;
}

.MapButtonAdd {
  background: #7e9294;
  border-width: 0 1px 1px 0;
  border-color: #324648;
  background: rgba(81, 83, 74, 0.8);
  border-style: solid;
  color: white;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.MapButtonAddContainer .MapButtonAdd {
  background: rgba(81, 83, 74, 0.8);
  border-width: 0 2px 2px 0;
  border-color: #51534a;
  border-style: solid;
  color: white;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 5;
}

.MapButtonAdd:hover {
  background: #51534a;
  color: white;
}

.MapButtonAddContainer .Feature {
  background: rgba(81, 83, 74, 0.6);
  border-width: 0px 2px 2px 0;
  border-color: #51534a;
  border-style: solid;
  color: white;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 4;
  transition: top 250ms;
  opacity: 0;
}

.MapButtonAddContainer.Show .MapButtonAdd {
  background: #51534a;
  color: white;
}

.MapButtonAddContainer.Show .Feature {
  opacity: 1;
}

.MapButtonAddContainer.Show .Feature:nth-child(2) {
  top: 30px;
}

.MapButtonAddContainer.Show .Feature:nth-child(3) {
  top: 60px;
}

.MapButtonAddContainer.Show .Feature:nth-child(4) {
  top: 90px;
}

.MapButtonAddContainer.Show .Feature:nth-child(5) {
  top: 120px;
}

.MapButtonAddContainer.Show .Feature:nth-child(6) {
  top: 150px;
}

.MapButtonAddContainer.Show .Feature:nth-child(7) {
  top: 180px;
}

.MapButtonAddContainer .Feature:hover {
  background: #51534a;
  color: white;
}

.MapButtonAddContainer .Feature:active {
  background: #51534a;
  color: white;
}

.MapButton {
  background: rgba(81, 83, 74, 0.8);
  border-width: 0 2px 2px 0;
  border-color: #51534a;
  border-style: solid;
  color: white;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.MapButton:hover {
  background: #51534a;
  color: white;
}

.MapButtonIcon {
  height: 15px;
  width: 15px;
  margin-left: 10px;
  float: right;
  transition: all 150ms;
}

.MapButtonAddContainer.Show .MapButtonIcon {
  -moz-transform: scale(1, -1);
  -webkit-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
