.LandingPage {
  min-height: 100vh;
  width: 100%;
  --width: min(80vw, 16000px);
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #91938822;
  font-family: neuzeit-grotesk, sans-serif;
}

.LandingHeader {
  width: 100%;
  background: #51534a;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LandingHeader .Icon {
  height: min(80px, 12vw);
  grid-column: 2 / 2;
  grid-row: 1 / 1;
}

.LandingHeader .IconDiv {
  display: grid;
  grid-template: 1fr / 1fr 3fr 1fr;
  place-items: center;
  width: 100%;
}

.LandingHeader:nth-child(even) {
  background: white;
}

.LandingPageLoginContainer {
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(145, 147, 136, 0.8);
  background: rgba(145, 147, 136, 0.3);
  background: hsl(73, 6%, 41%);
  /* height: 65px; */
}

.LandingPageLoginContainer {
  grid-column: 2 / 2;
  grid-row: 1 / 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.LandingPageButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.LandingPageFlagsContainer {
  grid-column: 3 / 3;
  grid-row: 1 / 1;
  justify-self: end;
}

.LandingPageLoginContainer .Button {
  height: 45px;
  width: 225px;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  font-weight: 400;
  font-size: 28px;
  margin: 0 5px;
}
.LandingPageLoginButton {
  border: 1px solid #919388;
  background-color: #91938899;
}

.LandingPageLoginButton.LandingPageActiveButton {
  border: 1px solid #78be20;
  background-color: #77be2099;
}
.LandingPageLoginBody {
  border: 1px solid #919388;
  background-color: #91938899;
  height: 200px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
  place-items: center;
  width: 100%;
}

.LandingPageLoginBody * {
  grid-column: 2 / 2;
}

.LandingPageLoginBody label {
  color: white;
  justify-self: end;
  padding: 0 10px;
  text-align: end;
  font-size: 1.2em;
  grid-column: 1 / 1;
}

.LandingPageLoginBody h3 {
  color: white;
  justify-self: end;
  padding: 0 10px;
  text-align: end;
  font-size: 2em;
  margin: 10px;
}
.LandingPageLoginBody label.Price {
  color: white;
  justify-self: start;
  padding: 0 10px;
  margin: 0;
  text-align: start;
  font-size: 2em;
  grid-column: 3 / 3;
  font-family: roboto, sans-serif;
}
.LandingPageLoginBody label.Price.Total {
  color: white;
  justify-self: start;
  padding: 0 10px;
  margin: 0;
  text-align: start;
  font-size: 2.5em;
  grid-column: 3 / 3;
}

.LandingPageLoginBody form {
  grid-column: 1 / 4;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
  place-items: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.LandingPageLoginBody img {
  justify-self: start;
  grid-column: 3 / 3;
  padding: 0 10px;
  cursor: pointer;
}


.LandingPageLoginBody p {
  color: white;
  font-size: 3em;
  grid-row: 1 / 3;
}

.LandingPageLoginBody .FormInput {
  color: #51534a;
  background: #ffffffdd;
  font-size: 1.2em;
  margin: 3px;
  height: 40px;
  text-align: center;
}

.LandingPageLoginBody .Button {
  color: white;
  padding: 24px;
  border-radius: 12px;
  width: 250px;
  border: 1px solid #78be20;
  background-color: #77be20cc;
  margin: 16px 0 0;
}

.LandingPageDrawingSpacer {
  height: max(
    calc(calc(100vh - 80px - 55px - 200px - calc(var(--width) * 0.2)) / 2),
    5vw
  );
}

.LandingPageLoginContainer p.Error {
  color: #c00d1e;
  font-weight: normal;
  font-size: 24px;
  color: white;
  background: #c00d1e;
  padding: 16px;
  border-radius: 8px;
  box-sizing: border-box;
  /* width: 90%; */
  margin: 16px 0 0;
}
.LandingPageDrawingSpacer p {
  color: #c00d1e;
  font-weight: normal;
  font-size: 24px;
  color: white;
  background: #c00d1e;
  padding: 16px;
  border-radius: 8px;
  box-sizing: border-box;
  /* width: 90%; */
  margin: 16px 0 0;
}

.LandingPageScreenshot {
  width: 55vw;
}

.LandingPagePackage {
  width: 100%;
  background: hsl(73, 6%, 41%);
  /* border-radius: 2vw ; */
  /* margin: 2vw 0 3vw 0; */
  overflow: hidden;
  background: #51534a77;
  filter: drop-shadow(1px 1px 8px #00000055);
}
.LandingPagePackage.Grass {
  background-color: #78be2022;
  /* margin: 2vw 0 5vw; */
}
.LandingPagePackage.Weighing {
  background-color: #f2a90022;
}
.LandingPagePackage.Environment {
  background-color: #00b2de22;
}

.LandingPagePackageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vw 5vw 0.2vw;
  box-sizing: border-box;
  color: white;
  width: 100%;
  /* filter: drop-shadow(1px 1px 3px #00000055); */
}
.LandingPagePackageHeader h1 {
  font-size: 2vw;
}
.LandingPagePackageHeader h2 {
  font-size: 1.5vw;
  font-style: italic;
  font-weight: 400;
}
.LandingPagePackageHeader .FieldIcon {
  width: 8vw;
}

.Grass .LandingPagePackageHeader {
  background-color: #78be2077;
}

.Weighing .LandingPagePackageHeader {
  background-color: #f2a90077;
}

.Environment .LandingPagePackageHeader {
  background-color: #00b2de77;
}

.LandingPagePackageBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vw 3vw 2vw;
  box-sizing: border-box;
}
.Weighing .LandingPagePackageBody {
  flex-direction: row-reverse;
}

.LandingPagePackageBody ul {
  padding: 0 5vw;
  margin: 0;
  flex-grow: 1;
}

.LandingPagePackageBody li {
  color: #51534a;
  font-size: calc(var(--width) * 0.025);
  margin: 0;
  font-weight: 400;
  line-height: calc(var(--width) * 0.04);
  letter-spacing: 0;
}

.SellingPackagesContainer {
  display: grid;
  grid-template: 2fr / 3fr 3fr 3fr;
  place-items: center;
  width: 90%;
}

.LandingPagePackageThird {
  width: 80%;
  margin: 10%;
  box-sizing: border-box;
  background: hsl(73, 6%, 41%);
  overflow: hidden;
  background: #51534a77;
  filter: drop-shadow(1px 1px 8px #00000055);
  border: 0.1vw solid #51534a;
}
.LandingPagePackageThird.Grass {
  background-color: #78be2022;
  border-color: #78be20;
  /* margin: 2vw 0 5vw; */
}
.LandingPagePackageThird.Weighing {
  background-color: #f2a90022;
  border-color: #f2a900;
}
.LandingPagePackageThird.Environment {
  background-color: #00b2de22;
  border-color: #00b2de;
}

.LandingPagePackageThird .LandingPagePackageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vw 2vw 0.2vw;
  box-sizing: border-box;
  color: white;
  width: 100%;
  /* filter: drop-shadow(1px 1px 3px #00000055); */
}
.LandingPagePackageThird .LandingPagePackageHeader h1 {
  font-size: 2vw;
}
.LandingPagePackageThird .LandingPagePackageHeader h2 {
  font-size: 1.2vw;
  line-height: 1.2vw;
  font-style: italic;
  font-weight: 400;
}
.LandingPagePackageThird .LandingPagePackageHeader .FieldIcon {
  width: 5vw;
}

.LandingPagePackageThird.Grass .LandingPagePackageHeader {
  background-color: #78be2077;
}

.LandingPagePackageThird.Weighing .LandingPagePackageHeader {
  background-color: #f2a90077;
}

.LandingPagePackageThird.Environment .LandingPagePackageHeader {
  background-color: #00b2de77;
}

.LandingPagePackageThird .LandingPagePackageBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 0;
  box-sizing: border-box;
}

.LandingPagePackageThird .LandingPagePackageBody ul {
  padding: 2vw 0 1vw 3vw;
  margin: 0;
  flex-grow: 1;
}

.LandingPagePackageThird .LandingPagePackageBody li {
  color: #51534a;
  font-size: calc(var(--width) * 0.02);
  margin: 0;
  font-weight: 400;
  line-height: calc(var(--width) * 0.025);
  letter-spacing: 0;
}

.LandingPagePackageThird .LandingPageScreenshot {
  width: 100%;
}

.LandingPagePrice {
  height: 16vw;
  padding: 0;
  text-align: center;
  font-size: calc(var(--width) * 0.02);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LandingPagePackageThird.Grass .LandingPagePrice {
  background-color: #78be2077;
}

.LandingPagePackageThird.Weighing .LandingPagePrice {
  background-color: #f2a90077;
}

.LandingPagePackageThird.Environment .LandingPagePrice {
  background-color: #00b2de77;
}

.LandingPageFooter {
  font-family: roboto, sans-serif;
  width: 100%;
  background: #51534a;
  filter: drop-shadow(1px 1px 8px #00000055);
  padding: max(0.66vw, 16px) 0;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: max(0.66vw, 16px);
}

.LandingPagePrice h1 {
  margin: 0 0 1vw;
  /* color: white; */
  text-align: center;
  font-size: 2.2vw;
  line-height: 2.5vw;
  font-family: roboto, sans-serif;
}
.LandingPagePrice h2 {
  margin: 1vw 0 0;
  /* color: white; */
  text-align: center;
  font-size: 1.5vw;
  line-height: 1.5vw;
}

.LandingPagePackageAll {
  width: 93.3%;
  margin: 1.67% 3.3% 3.3%;
  box-sizing: border-box;
  background: hsl(73, 6%, 41%);
  overflow: hidden;
  background: #51534a22;
  filter: drop-shadow(1px 1px 8px #00000055);
  grid-column: 1 / 4;
  border: 0.1vw solid #51534a;
}

.LandingPagePackageAll .LandingPagePackageHeader {
  background: #51534a99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vw 2vw 0.2vw;
  box-sizing: border-box;
  color: white;
  width: 100%;
}
.LandingPagePackageAll .LandingPagePackageHeader .FieldIcon {
  width: 5vw;
}

.LandingPagePackageAll .LandingPagePackageBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 0;
  box-sizing: border-box;
}

.LandingPagePackageAll .LandingPagePackageBody ul {
  padding: 2vw 0 2vw 3vw;
  margin: 0;
  flex-grow: 1;
}

.LandingPagePackageAll .LandingPagePackageBody li {
  color: #51534a;
  font-size: calc(var(--width) * 0.02);
  margin: 0;
  font-weight: 400;
  line-height: calc(var(--width) * 0.025);
  letter-spacing: 0;
}

.LandingPagePackageAll .LandingPageScreenshot {
  width: 100%;
}

.LandingPagePackageAll .LandingPagePrice {
  /* background-color: #51534a99; */
  color: #51534a;
  
  /* height: 8vw; */
}

.FormInput option {
  color: #51534a;
}

.LandingPage .StripeElement {
  /* background-color: white;
  height: 30px;
  font-size: 16px;
  width: calc(100%);
  padding: 6px 10px;
  border-radius: 3px;
  border: 1px solid var(--border-light);
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;
  box-sizing: border-box; */

  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(145, 147, 136, 0.8);
  border-radius: 3px;
  color: #51534a;
  background: #ffffffdd;
  font-size: 1.2em;
  margin: 3px;
  height: 40px;
  text-align: center;
  box-shadow: none;
  padding: 10px;
}
