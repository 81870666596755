.StripeElement {
  background-color: white;
  height: 30px;
  font-size: 16px;
  width: calc(100%);
  padding: 6px 10px;
  border-radius: 3px;
  border: 1px solid var(--border-light);
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  box-sizing: border-box;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #ffffff !important;
}
