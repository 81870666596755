:root {
  /* Border colors */
  --border: #51534a;
  --border: #51534a;
  --border-light: #dcdcdc;
  /* Background colors */
  --bg-1: #ffffff;
  --bg-2: #f7f7f7;
  --bg-3: #eeeeee;
  /* Text colors */
  --text-light: white;
  --text-dark: #51534a;
  /* Grass colors */
  --grass-strong: #51534a;
  --grass-light: #78be20;
  --grass-lighter: #51534a;
  --grass-accent-light: #abc1ed;
  --grass-accent1: #4f596e;
  --grass-accent2: #0a3d54;
  --grass-accent3: #cccccc;
  --grass-excluded: #dd1010;
  /* Soil colors */
  --soil-strong: #ff773c;
  --soil-light: #f6fa47;
  --soil-accent1: #c72625;
  --soil-accent2: #7a3723;
  /* Neutral colors */
  --neutral-strong: #51534a;
  --neutral-light: #afbfcf;
  --neutral-accent1: #51534a;
  --neutral-accent2: #51534a;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #51534a;
  color: var(--text-dark);
}

.App {
  width: 100%;
  height: 100vh;
  line-height: 100%;
  font-family: 'Roboto';
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
}
a {
  color: #FFFFFF;
}
.Main {
  background: white;
  max-width: 100vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100vh;
  max-width: calc(100vw - 230px);
  width: calc(100vw - 230px);
  max-width: 100vw;
  width: 100vw;
}
.Main.mobile {
  background: white;
  max-width: 100vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100vh;
  max-width: 100vw;
  width: 100vw;
}

textarea {
  resize: none;
}
.Header {
  background: #51534a;
  /* background: #001315; */
  background: rgba(120,190,32, 0.2);
  background: rgba(145, 147, 136, 0.7);
  /* background: rgba(24, 44, 46, 0.9); */
  background: #51534a;
  border-top: none;
  border-bottom: none;
  /* background: linear-gradient(90deg, lightsteelblue, lightgray);     */
  height: 65px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  /* margin: 0 0 20px; */
  /* padding: 10px calc(50vw - 700px) 4px; */
  /* border-bottom: 2px solid #324648;
  border-bottom: 2px solid rgba(120,190,32, 0.7);
  border-bottom: 2px solid rgba(120,190,32, 1); */
  /* border-bottom: 2px solid rgba(120,190,32, 0.2);
  border-bottom: 2px solid rgba(145, 147, 136, 1); */
  /* border-top: 3px solid #78be20; */
  box-sizing: border-box;
  color: white;
  color: #51534a;
  box-shadow: 0 0 3px 1px #51534a;

  z-index: 999;
}

.Header .NavDiv {
  /* height: 44px; */
  box-sizing: border-box;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  /* width: 100%; */
  /* background: rgba(120,190,32, 0.6);
  background: rgba(255, 255, 255, 0.7); */
  /* padding: 3px 3px 1px; */
  /* border-radius: 10px; */
  text-align: center;
  /* border-left: 2px solid #51534a; */
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  /* filter: invert(0.9); */
}

.Header_group {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  /* width: calc(100% - 100px); */
  /* padding: 4px 40px 4px;
  box-sizing: border-box; */
  display: -webkit-flex;
  display: flex;
  /* flex-direction: column; */
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0px;
  /* background-color: white; */
}

.HeaderActions {
  padding: 4px 0;
  /* height: 100%; */
  display: -webkit-flex;
  display: flex;
  /* flex-direction: column;
  align-items: flex-start;
  justify-content: space-between; */
  /* padding: 0px; */
  /* background-color: white; */
}

.MenuToggle {
  width: 50px;
  height: 50px;
  padding-right: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: none;
  cursor: pointer;
}

.Bar {
  margin: 3px;
  height: 4px;
  width: 30px;
  background: #51534a;
  background: #51534a;
  border-radius: 2px;
  pointer-events: none;
}

.Title {
  font-size: 25px;
  /* color: #51534a; */
  white-space: nowrap;
  /* padding-left: 20px; */
  /* font-weight: bold; */
}
.Title.mobile {
  font-size: 22px;
}

.FlagDiv {
  /* width: 145px; */
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  /* background-color: white; */
}

.FlagDiv .Flag {
  margin: 2px 3px;
  width: 50px;
  height: 30px;
  object-fit: fill;
  cursor: pointer;
}

.FlagDiv .InactiveFlags {
  display: -webkit-flex;
  display: flex;
  height: 100%;

  -webkit-flex-direction: column;

          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;

}

.FlagDiv .InactiveFlags .Flag {
  width: 30px;
  height: 18px;
}

.WeighRiteLogo {
  height: 58px;
}


.HeaderLinks {
  padding: 4px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}


.Header .IconDiv {
  height: 100%;
  box-sizing: border-box;
  padding: 0px 16px;
  text-align: center;
  
  display: -webkit-flex;
  
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}


.Header .Icon {
  height: 45px;
  -webkit-filter: drop-shadow(1px 1px 3px #000000a5);
          filter: drop-shadow(1px 1px 3px #000000a5);
}
.Header ul {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.HeaderTab {
  padding: 10px;
  margin: 2px 4px;
  box-sizing: border-box;
  font-size: 18px;
  letter-spacing: 0.3px;
  cursor: pointer;
  text-align: center;
  transition: all 250ms;
  color: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
}
.HeaderTab.active {
  background: rgba(0, 0, 0, 0.3);
  color: white;
  box-shadow: 0 0 0 1px rgb(255, 255, 255) inset;
}

.HeaderTab:hover {
  background: #78be20;
  background: rgba(255, 255, 255, 1);
  background: rgba(0, 0, 0, 0.2);
  color: white;
  color: #78be20;
}

.HeaderTab.active:hover {
  background: rgba(0, 0, 0, 0.5);
  color: #78be20;
  box-shadow: 0 0 0 1px #78be20 inset;
}
.LoginWrapper {
  box-shadow: 2px 2px 8px darkgrey;
  width: 500px;
  max-width: 90vw;
  overflow-y: auto;
  margin-bottom: calc(60px + 1vw);
}

.LoginWrapper .LoginContainer {
  width: 500px;
  max-width: 90vw;
  border-color: #8f807d;
  border-style: solid;
  border-width: 0;
  background: white;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  overflow-y: auto;
}

.Main .LoginContainer {
  width: 100%;
  max-width: 90vw;
  height: calc(100%);
  border: none;
  border-width: 0 3px;
  background: white;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  overflow-y: auto;
}

.Login {
  width: 400px;
  max-width: calc(90vw - 60px);
  border-left: none;
  border-right: none;
  padding: 30px;
  position: relative;
  float: left;
}

.Login .Button {
  height: 30px;
  border-radius: 5px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: #8f807d;
  border: none;
  cursor: pointer;
  margin: 20px 40px 0 40px;
  color: white;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.7px;
  margin-bottom: 20px;
  border: 1px solid rgba(81, 83, 74, 1);
  background-color: rgba(81, 83, 74, 0.7);
}

.Login .Button:hover {
  color: white;
  background: #51534a;
}

.Login .Button.inline {
  display: -webkit-inline-flex;
  display: inline-flex;
  width: 50%;
  max-width: 45vw;
  margin: 0;
}

h3 {
  text-align: center;
  font-size: 18px;
  color: #51534a;
  margin: 30px 0px 30px 0px;
  position: static;
}

.Login .FormInput {
  text-align: center;
  font-size: 14px;
  color: #51534a;
  margin: 0px 0px 30px 0px;
}

p {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #51534a;
  margin: 10px 0px 10px 0px;
}

label {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #51534a;
  margin: 10px 0px 10px 0px;
}

.LoginNav {
  width: 500px;
  max-width: 90vw;
  min-height: 30px;
  display: -webkit-flex;
  display: flex;
}

.LoginButton {
  width: 250px;
  max-width: 45vw;
  height: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: whitesmoke;
  box-shadow: inset 1px -1px 2px lightgrey;
  box-sizing: border-box;
  border-color: #51534a;
  border-style: solid;
  border-width: 0px;
  font-weight: 600;
  cursor: pointer;
  z-index: 1;
}

.LoginButton.Left {
  box-shadow: inset -1px -1px 2px lightgrey;
}

.LoginButton.Active {
  background: white;
  border-width: 3px 0 0 0;
  cursor: auto;
  box-shadow: none;
}

.Footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  min-height: 60px;
  margin-top: 60px;
  z-index: 2;
  background: white;
  border-top: 2px solid #51534a;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px darkgrey;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.LoginLogoLarge {
  width: 500px;
  max-width: 90vw;
  margin: 80px;
}

.Message {
  font-size: 20px;
  color: #51534a;
  font-weight: 600;
}

.StripeElement {
  background-color: white;
  height: 30px;
  font-size: 16px;
  width: calc(100%);
  padding: 6px 10px;
  border-radius: 3px;
  border: 1px solid var(--border-light);
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;
  box-sizing: border-box;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #ffffff !important;
}

.BrowserBody {
  /* background: white; */
  width: 100%;
  height: calc(100vh - 65px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  overflow-y: scroll;
  background: #91938822;
  color: #51534a;
}

.BrowserBody a {
  color: #51534a;
}

.BrowserBody.Half {
  height: 50%;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  overflow-y: unset;
  /* background: rgba(233,233,233,0.6); */
}

.BrowserIconContainer {
  max-width: 100%;
  max-height: calc(100vh - 65px);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.FarmIcon {
  background: #fff8;
  /* border: 0.2vw solid #78be2099; */
  /* background: white; */
  /* filter: drop-shadow(2px 2px 5px #0006); */
  border-radius: 20px;
  width: 200px;
  font-family: neuzeit-grotesk, sans-serif;
  height: 200px;
  cursor: pointer;
  /* box-sizing: border-box; */
}

.FarmIcon.Disabled {
  background: #fff8;
  cursor: unset;
  /* color: #c00d1e; */
}

.FarmIcon.Disabled .Paddock {
  fill: #fff8;
  /* stroke: green; */
}

.FarmIconTitle {
  fill: #51534a;
  font-weight: normal;
  font-size: 22px;
}

.AddFarmContainer {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.FileInputContainer {
  width: 345px;
  height: 40px;
  overflow: hidden;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  /* cursor: pointer; */
}

.FileInputContainer:hover .FileButton {
  background: #51534a;
  color: white;
}

.FileInput {
  font-size: 10000px;
  opacity: 0;
  cursor: pointer;
  position: absolute;
}

.FileButton {
  padding: 0 10px;
  color: #51534a;
  border: 2px solid #51534a;
  background: white;
  height: 30px;
  box-sizing: border-box;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  pointer-events: none;
  transition: -webkit-transform 100ms box-shadow 100ms;
  transition: transform 100ms box-shadow 100ms;
  transition: transform 100ms box-shadow 100ms, -webkit-transform 100ms box-shadow 100ms;
}

.FileInputContainer p {
  margin-left: 10px;
}

.AddFarmContainer .ButtonContainer {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.AlphabetContainer {
  padding: 10px;
  width: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}


nav.react-contextmenu {
  position: fixed;
  width: 200px;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 4px;
  background-color: #fff;
  padding: 10px 0;
  z-index: 99;
  box-shadow: 0 0 20px rgba(0,0,0,.2);
}
.react-contextmenu .react-contextmenu-item:hover{
background: #cccccc;
}

.react-contextmenu-item:hover:not(.react-contextmenu-item--disabled) {
background-color: #f1f1f1;
}

.react-contextmenu .react-contextmenu-item{
font-size: 14px;
display: block;
text-decoration: none;
padding: 10px 15px;
cursor: pointer;
-webkit-user-select: none;
        user-select: none;
transition: .2s;
position: relative;
margin-bottom: 2px;
font-weight: 500;
display: -webkit-flex;
display: flex;
-webkit-align-items: center;
        align-items: center;
outline: none;
}
.react-contextmenu-item span{
margin-left: 10px;
}
.react-contextmenu-item svg{
font-size: 14px;
}
.react-contextmenu-item .copy{
color:skyblue
}
.react-contextmenu-item .delete{
color:red
}
.react-contextmenu-item .send{
color: blue
}
.react-contextmenu-item .share{
color: green
}
.react-contextmenu-item .watchlist{
color: rebeccapurple
}
.Calendar .CalContainer {
  padding: 10px 30px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%;
}

.Calendar .CalHeader {
  padding-bottom: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.CalNav {
  cursor: pointer;
}

.CalBody {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.CalBody table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  text-align: center;
  border-collapse: collapse;
}

.CalBody table thead {
  background: #eee;
  border: 2px solid #e8e8e8;
}

.CalBody table tbody {
  background: #fcfcfc;
  border: 2px solid #e8e8e8;
}

.CalBody table th,
.CalBody table td {
  padding: 2px 4px;
  border: 1px solid #e8e8e8;
}

.CalWeekend {
  background: #f6f6f6;
}

.CalSurvey {
  padding: 2px 0 3px;
  font-weight: bold;
  background: rgba(188, 224, 159, 0.4);
  background: rgba(120,190,32, 0.7);
  background: rgba(81, 83, 74, 0.25);
  color: #000;
  font-size: 1.1em;
  cursor: pointer;
}

.CalDisp {
  padding: 2px 0 4px;
  font-weight: bold;
  background: #51534a;
  background: rgba(120,190,32, 1);
  color: white;
  font-size: 1.3em;
  cursor: pointer;
}

.WeighingSessionSelector {
  margin: 8px 12px;
  padding: 8px 16px;
  background-color: #51534a;
  color: white;
  border-radius: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  cursor: pointer;
  font-size: medium;
  line-height: normal;
}

.WeighingSessionSelector.Selected {
  background-color: #78be20;
  font-weight: bold;
}

.ProportionLineContainer {
  margin: 8px 12px;
  /* height: 60px; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.Proportion {
  width: 3px;
}

.ProportionLabel {
  height: 20px;
  display: -webkit-flex;
  display: flex;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  box-sizing: border-box;
}

/* .ProportionLabel span {
  padding: 0 8px;
}

.Proportion:first-child .ProportionLabel {
  justify-content: start;
}
.Proportion:last-child .ProportionLabel {
  justify-content: end;
}
.Proportion:only-child .ProportionLabel {
  justify-content: center;
} */

.ProportionLine {
  height: 30px;
  display: -webkit-flex;
  display: flex;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  font-weight: bold;
}

/* .ProportionLine span {
  padding: 0 8px;
} */

.Proportion:first-child .ProportionLine {
  border-radius: 6px 0 0 6px;
  /* justify-content: start; */
}
.Proportion:last-child .ProportionLine {
  border-radius: 0 6px 6px 0;
  /* justify-content: end; */
}
.Proportion:only-child .ProportionLine {
  border-radius: 6px;
  /* justify-content: center; */
}

.TagButtonContainer {
  border: 1px solid#f2ba00;
  border-radius: 8px;
  margin: 8px;
  overflow: hidden;
}

.TagButton {
  background-color: #f2ba0033;
  /* color: white; */
  font-size: larger;
  /* border-radius: 7px; */
  padding: 8px 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  font-size: medium;
  line-height: normal;
  font-weight: bold;
  /* transition: all 250ms; */
}
.TagButton.Filtered {
  background-color: #f2ba00;
}

/* .TagButton.Expanded {
  border-radius: 8px 8px 0 0;
} */

.TagButton span {
  margin: 0 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  font-size: medium;
  line-height: normal;
  font-weight: bold;
}

.TagButton span:last-child {
  font-size: 1.1em;
}

.AnimalDetails {
  padding: 0 4px 8px;
}

.AnimalDetails {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 0;
  height: 0;
  overflow-y: hidden;
  transition: all 250ms;
}

.AnimalDetails.Show {
  height: 180px;
  padding: 0 4px 8px;
}

.AnimalDetailsRow {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 2px 8px;
  font-size: larger;
}

.WeightRightPanel {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.HalfWidth {
  width: 400px;
  height: calc((100vh - 65px));
  overflow-y: scroll;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Body {
  width: 100%;
  height: calc(100vh - 65px);
}

.Loading {
  width: 100%;
  height: calc((100vh - 65px));
  background: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Loading p {
  text-align: center;
}

.Spinner {
  width: 50px;
  height: 50px;
  -webkit-animation: rotation 500ms infinite linear;
          animation: rotation 500ms infinite linear;
}

.LargeSpinner {
  width: 100px;
  height: 100px;
  -webkit-animation: rotation 500ms infinite linear;
          animation: rotation 500ms infinite linear;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

.ChartContainer {
  width: 100%;
  height: calc((100vh - 65px));
  position: relative;
  display: -webkit-flex;
  display: flex;
}
.ChartContainer.mobile {
  -webkit-flex-direction: column;
          flex-direction: column;
}

.ChartCol {
  width: 50%;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

.ChartCol.scroll {
  overflow-y: scroll;
}
.ChartCol.mobile {
  width: 100%;
}

.ChartCol:first-child {
  border-right: 1px solid rgba(145, 147, 136, 0.8);
}

.ChartRow {
  width: 100%;
  display: -webkit-flex;
  display: flex;
}

.ChartRow.Top {
  height: 64%;
  border-bottom: 5px solid white;
  box-sizing: border-box;
}

.ChartRow.Bottom {
  height: calc(36%);
}

.Chart {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: calc(50% - 35px);
  position: relative;
}

.Chart.fullheight {
  height: 100%;
}

.Chart.mobile {
  height: calc(50% - 90px);
}

#FarmMap {
  border-bottom: 1px solid rgba(145, 147, 136, 0.8);
}

.DashboardTabList {
  height: 35px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(145, 147, 136, 0.8);
  background: rgba(145, 147, 136, 0.3);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.DashboardTabList .TabsLabel {
  width: 85px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  box-sizing: border-box;
  height: calc(100%);
  color: #000;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
}

.WedgeTab {
  background: rgba(255, 255, 255, 0.6);
  margin: 0px 2px;
  padding: 6px;
  letter-spacing: 0.3px;
  cursor: pointer;
  transition: all 250ms;
  border-radius: 3px;
}

.WedgeTab.active {
  color: white;
  background: rgba(81, 83, 74, 0.8);
}

.WedgeTab:hover {
  background: rgba(255, 255, 255, 1);
}

.WedgeTab.active:hover {
  background: #51534a;
}

.ScrollPanelItem {
  height: 250px;
  min-height: 250px;
}

.ScrollPanelItem.Row {
  display: -webkit-flex;
  display: flex;
}

.ScrollPanelHalfWidth {
  height: 250px;
  min-height: 250px;
  width: 50%;
}

.ScrollPanelItem.mobile {
  height: 180px;
}
.ScrollPanelItemHeader {
  padding: 8px 12px;
  border-top: 1px solid rgba(145, 147, 136, 0.4);
  border-bottom: 1px solid rgba(145, 147, 136, 0.4);
  background-color: rgba(145, 147, 136, 0.3);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.ScrollPanelItem:not(:nth-last-child(3)) .ScrollPanelItemHeader.Collapsed {
  border-bottom: none;
}

.ScrollPanelItem:nth-last-child(3) .MetricsPanel {
  border-bottom: 1px solid rgba(145, 147, 136, 0.4);
}

.ScrollPanelItemHeader h3 {
  color: #000;
}

.ScrollPanelItemHeader .DropIcon {
  height: 16px;
  width: 16px;
  margin-left: 10px;
  -webkit-filter: invert(1);
          filter: invert(1);
}
.ScrollPanelItemHeader .DropIcon.Expanded {
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.MetricGroup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.MetricGroupHeader {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  border-bottom: 2px solid rgba(145, 147, 136, 0.4);
  padding: 10px 2px 0;
  font-size: 16px;
  font-weight: bold;
}

.MetricGroup .Metric {
  padding: 10px;
  box-sizing: border-box;
  width: 50%;
}

.OptionButton {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
}

.FormGroup {
  padding: 10px;
  box-sizing: border-box;
}

@media (min-width: 1300px) {
  .MetricGroup .Metric {
    width: 33%;
  }
  .OptionButton {
    width: 50%;
  }
}

@media (min-width: 1700px) {
  .MetricGroup .Metric {
    width: 25%;
  }
  .OptionButton {
    width: 33%;
  }
}

.MetricGroup .Metric .FormInput {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 14px;
  -webkit-justify-content: center;
          justify-content: center;
}

.Chart:first-child {
  border-left: none;
}

.Chart.Full {
  width: 100%;
  height: calc(100%);
}

.Chart.FullWidth {
  width: 100%;
}

.OverflowHidden {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.tooltip {
  pointer-events: none;
  position: absolute;
  opacity: 0;
  background: #51534a;
  color: white;
  font-size: 14px;
  text-align: center;
  padding: 5px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 10px;
  z-index: 5;
}

.tooltip::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -15px;
  bottom: -20px;
  left: 50%;
  box-sizing: border-box;
  border: 10.6px solid #51534a;
  border-color: transparent transparent #515858 #51534a;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.LegendTitle {
  fill: #51534a;
  font-size: 14px;
  letter-spacing: 0.3px;
}

.ChartHeading {
  fill: #51534a;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.7px;
  position: static;
}

.ChartHeading.small {
  font-weight: 600;
  font-size: 16px;
}

.PaddockName {
  fill: white;
  font-weight: bold;
  letter-spacing: 0.3px;
  font-size: 20px;
  
  font-size:  18px;
  paint-order: stroke;
  vector-effect: non-scaling-stroke;
  stroke: #51534a;
  stroke-width: 3px;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  font-weight: 800;
}

.Popover_right {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: -263px;
  width: 260px;
  max-height: 100%;
  overflow-y: auto;
  background: white;
  transition: right 250ms;
  border-radius: 0 0 0 0;
  pointer-events: all;
}

.Popover_right.toggled {
  right: 0;
  border-left: 2px solid rgba(173, 160, 153, 0.4);
  border-bottom: 2px solid rgba(173, 160, 153, 0.4);
  border-top: 2px solid rgba(173, 160, 153, 0.4);
}

.PopoverToggle_right {
  box-sizing: border-box;
  position: absolute;
  font-size: 30px;
  color: #51534a;
  top: 5px;
  right: -2px;
  width: 30px;
  height: 30px;
  background: white;
  border: 2px solid rgba(173, 160, 153, 0.4);
  border-right: 0;
  transition: right 250ms;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
  pointer-events: all;
}

.PopoverToggle_right.toggled {
  right: 258px;
}

.Popover_left {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: -263px;
  width: 260px;
  max-height: 100%;
  overflow-y: auto;
  background: white;
  transition: left 250ms;
  border-radius: 0 0 0 0;
  pointer-events: all;
}

.Popover_left.toggled {
  left: 0;
  border-right: 2px solid rgba(173, 160, 153, 0.4);
  border-bottom: 2px solid rgba(173, 160, 153, 0.4);
  border-top: 2px solid rgba(173, 160, 153, 0.4);
}

.PopoverToggle_left {
  box-sizing: border-box;
  position: absolute;
  font-size: 30px;
  color: #51534a;
  top: 5px;
  left: -2px;
  width: 30px;
  height: 30px;
  background: white;
  border: 2px solid rgba(173, 160, 153, 0.4);
  border-left: 0;
  transition: left 250ms;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  pointer-events: all;
}

.PopoverToggle_left.toggled {
  left: 258px;
}

.Popover_right .FormGroup {
  padding: 10px;
}

.Popover_right .FormGroup .Right {
  padding: 5px 0 0;
  margin: 0;
  text-align: right;
}

.Popover_right .FormGroup .Flex {
  padding: 5px 0 0;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Popover_right .FormGroup .None {
  display: none;
}

.Popover_left .FormGroup {
  padding: 10px;
}

.Popover_left .FormGroup .Right {
  padding: 5px 0 0;
  margin: 0;
  text-align: right;
}

.Popover_left .FormGroup .Flex {
  padding: 5px 0 0;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Popover_left .FormGroup .None {
  display: none;
}

.FormInput {
  display: block;
  width: 100%;
  height: 30px;
  font-size: 12px;
  color: #000;
  padding: 3px;
  box-sizing: border-box;
  border: 1px solid #51534a;
  border: 1px solid rgba(145, 147, 136, 0.8);
  border-radius: 3px;
  background-color: rgba(145, 147, 136, 0.1);
}
td .FormInput {
  text-align: center;
}

.FormInput:disabled {
  opacity: 0.5;
}

.Radio {
  color: #7c807b;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.FormInputGroup {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.Button {
  height: 30px;
  border-radius: 5px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: white;
  cursor: pointer;
  font-weight: normal;
  letter-spacing: 0.7px;
  border: 1px solid rgba(81, 83, 74, 1);
  background-color: rgba(81, 83, 74, 0.7);
}
.Button.Green {
  border: 1px solid #78be20;
  background-color: rgba(120,190,32, .6 );
  color: #000;
  transition: all 250ms;
}
.Button.Green:hover {
  background-color: #78be20;
  color: #fff;
}
.Button.Red {
  border: 1px solid #c00d1e;
  background-color: rgba(192, 13, 30, .4);
  color: #000;
  transition: all 250ms;
}
.Button.Red:hover {
  background-color: #c00d1e;
  color: #fff;
}
.Button.Grey {
  border: 1px solid rgba(145, 147, 136, 1);
  background-color: rgba(145, 147, 136, 0.7);
  transition: all 250ms;
}
.Button.Grey:hover {
  background-color: rgba(145, 147, 136, 1);
  
}
.Button.Action {
  border: 1px solid rgba(81, 83, 74, 1);
  background-color: rgba(81, 83, 74, 0.7);
  letter-spacing: 0.3px;
  cursor: pointer;
  transition: all 250ms;
}
.Button.Action:hover {
  background-color: rgba(81, 83, 74, 1);
  
}

.FormGroup .Button {
  margin: 20px 40px 0 40px;
}

.FormGroup .Button:hover {
  color: white;
  background: #51534a;
}

.InfoTableContainer {
  width: 50%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.InfoTable {
  width: 100%;
  font-size: 14px;
  text-align: center;
  border-collapse: collapse;
}

.InfoTable tr:nth-child(odd) th {
  color: #51534a;
}

.InfoTable tr:nth-child(even) th {
  color: #51534a;
}

.InfoTable tr:nth-child(odd) td {
  color: #51534a;
}

.InfoTable tr:nth-child(even) td {
  color: #51534a;
}

.InfoTable tr th {
  text-align: right;
  padding: 3px 8px 3px 20px;
  width: 50%;
  border-radius: 10px 0 0 10px;
  font-weight: normal;
}

.InfoTable tr td {
  text-align: left;
  padding: 3px 20px 3px 8px;
  border-radius: 0 10px 10px 0;
  font-weight: normal;
}

.InfoTable tr th.InfoTableHeader {
  font-size: 20px;
  text-align: center;
  padding: 3px 8px;
  border-radius: 10px;
  font-weight: bold;
}

.FloatingSelect {
  position: absolute;
  left: 5px;
  top: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

#FarmMapSVG svg g rect {
  -webkit-filter: drop-shadow(1px 1px 3px #00000055);
          filter: drop-shadow(1px 1px 3px #00000055);
}

.Paddock {
  stroke: green;
  stroke: #51534a;
  fill: rgba(120,190,32, .6);
  stroke-width: 1px;
  -webkit-filter: drop-shadow(1px 1px 3px #00000055);
          filter: drop-shadow(1px 1px 3px #00000055);
}

.Paddock.unmeasured {
  fill: transparent;
  stroke-width: 1px;
}

.Paddock.excluded {
  stroke: #c00d1e;
  opacity: .5;
}

.Paddock.ignored {
  opacity: .5;
}

.Paddock.selector.active {
  stroke: #51534a;
  fill: #51534a;
}

.Paddock.selector:hover {
  stroke: #51534a;
  stroke-width: 2px;
}

.Point {
  stroke: #51534a;
  fill: rgba(120,190,32, .6);
  stroke-width: 1px;
  -webkit-filter: drop-shadow(1px 1px 3px #00000055);
          filter: drop-shadow(1px 1px 3px #00000055);
}

.Point.active {
  fill: rgba(255, 176, 0, 0.6);
}

.WedgeBar {
  stroke: #51534a;
  fill: rgba(120,190,32, .8);
  stroke-width: 1px;
  cursor: pointer;
  -webkit-filter: drop-shadow(1px 1px 3px #00000055);
          filter: drop-shadow(1px 1px 3px #00000055);
}

.WedgePadding {
  stroke: #ffffff;
  /* fill: #7dc243; */
  stroke-width: 2px;
  pointer-events: none;
}

.WedgeBar.active {
  stroke: #51534a;
  fill: rgba(81, 83, 74, 0.6);
}

.WedgeBar.unmeasured {
  fill: rgba(120,190,32, .4);
}

.WedgeBar.unmeasured.active {
  fill: #4eafb7;
}

.WedgeProj {
  stroke: none;
  fill: rgba(120,190,32, .4);
}

.WedgeProj.unmeasured {
  stroke: none;
  fill: none;
}

.WedgeLabel {
  fill: #51534a;
  text-anchor: start;
  pointer-events: none;
}

.WedgeLabel.active {
  fill: white;
  text-shadow: 0px 0px 2px #51534a;
}

.HistoryLine {
  stroke: #cccccc;
  fill: none;
  stroke-width: 2px;
}

.YTD {
  fill: rgba(120,190,32, .4);
}

.YTD.selected {
  fill: rgba(120,190,32, 1);
}

.Rotations {
  stroke: white;
  fill: rgba(120,190,32, .4);
  stroke-width: 2px;
}

.Rotations.complete {
  fill: rgba(120,190,32, 1);
}

.svgMessage {
  font-size: 20px;
  text-anchor: middle;
  fill: #51534a;
}

.GrowthArea {
  stroke: none;
  fill: rgba(120,190,32, .8);
  pointer-events: none;
  -webkit-filter: drop-shadow(1px 1px 3px #00000055);
          filter: drop-shadow(1px 1px 3px #00000055);
}

.GrowthCurve {
  stroke: #51534a;
  fill: none;
  stroke-width: 1px;
}

.grid line,
.grid path {
  stroke: #ccc;
}

.axis line,
.axis path {
  stroke: #ccc;
}

.axis text {
  fill: #51534a;
  font-family: 'Roboto';
  /* font-weight: bold; */
}

svg text {
  pointer-events: none;
}

.Difference {
  stroke: none;
  fill: rgba(242, 169, 0, 0.4);
}

.TextBackdrop {
  stroke: none;
  fill: rgba(242, 169, 0, 0.4);
  pointer-events: none;
}

.BlockTable {
  table-layout: fixed;
}

.ToggleContainer {
  background-color: #f2f2f2;
  border-radius: 3px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 10px;
}

.Toggle {
  background-color: #f2f2f2;
  border-radius: 0 5px 5px 0;
  font-size: 16px;
  width: 50%;
  padding: 4px 8px;
  text-align: center;
  border: 1px solid rgba(81, 83, 74, 0.7);;
}

.Toggle:first-child {
  border-radius: 5px 0 0 5px;
  border-right: 0px;
}

.Toggle.Active {
  background-color: rgba(81, 83, 74, 0.7);
  color: white;
}

.Toggle:hover {
  background-color: rgba(81, 83, 74, 0.2);
}

.Toggle.Active:hover {
  background-color: rgba(81, 83, 74, 1);
}

.PBIExportTitle {
  line-height: normal;
  font-size: 1.7em;
  text-align: center;
}

.PBIExportContent {
  line-height: normal;
  font-size: 1.4em;
  text-align: center;
}

.CopyrightContainer {
  text-align: center;
  font-size: 16px;
  padding: 12px 0;
  color: #000;
}
.WeatherStation {
  fill: #ffffff;
  opacity: 0.3;
  /* stroke: #fff;
  stroke-width: 1px; */
  -webkit-filter: drop-shadow(1px 1px 3px #000000a5);
          filter: drop-shadow(1px 1px 3px #000000a5);
}

.WeatherStation.Active {
  fill: #78be20;
  opacity: 1;
  -webkit-filter: drop-shadow(1px 1px 3px #00000055);
          filter: drop-shadow(1px 1px 3px #00000055);
}

.ChartSVG line {
  stroke: #ccc;
  stroke-width: 1px;
}


.ChartSVG rect {
  -webkit-filter: drop-shadow(1px 1px 3px #00000055);
          filter: drop-shadow(1px 1px 3px #00000055);
}

.Soil {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.Soil .SoilCol {
  width: 42%;
  height: 100%;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Soil .SoilCol:first-child {
  width: 58%;
}

.Soil .SoilRow.Bottom {
  height: 36%;
}

.Soil .SoilRow {
  width: 100%;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  border-bottom: 5px solid white;
}

.Soil .SoilRow:last-child {
  width: 100%;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  border-bottom: none;
}

.Soil .SoilRow.Top {
  height: 64%;
}

.Soil .SoilRow.Bottom {
  height: 36%;
}

.SoilSelectDiv {
  height: 100%;
  width: 50%;
  box-sizing: border-box;
  overflow-y: auto;
  padding: 0px 20px 10px 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  /* align-items: center; */
}

.SoilSelectDiv label {
  margin-top: 0;
  text-align: left;
}

.SoilMapDiv {
  height: calc(100% - 103px);
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.SoilDisplayDiv {
  height: 100%;
  box-sizing: border-box;
  width: 50%;
  padding: 10px;
}

.SoilDisplayDiv .ActionsGraphicDiv {
  height: calc(100% - 100px);
  box-sizing: border-box;
  width: 50%;
  margin: 14px 0px 20px 0px;
}

.SoilTableDiv {
  height: calc(100% - 260px);
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.SoilButton {
  height: 30px;
  border-radius: 5px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: white;
  font-weight: bold;
  letter-spacing: 0.7px;
  background: #8f807d;
  border: none;
  cursor: pointer;
  margin-top: 5px;
}

.SoilButton:hover {
  color: white;
  background: #51534a;
}

.RemoveButton {
  color: #c00d1e;
  border-color: #c00d1e;
}

.RemoveButton:hover {
  color: white;
  background: #c00d1e;
}

.AddButton {
  color: #78be20;
  border-color: #78be20;
}

.AddButton:hover {
  color: white;
  background: #78be20;
}

.FlexP {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 0px;
}

.FlexP span {
  font-size: 13px;
  line-height: 10px;
}

.Paddock.soil {
  stroke: #51534a;
  fill: #f2f2f2;
  stroke-width: 1px;
}

.ChartHeading.soil {
  fill: #51534a;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.7px;
}

.SoilSelectDiv .ChartHeading {
  fill: #51534a;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.7px;
  text-align: center;
}

.SoilDateSelector {
  width: 50%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.SoilDateSelector .DateSelectorContainer {
  width: 100%;
  height: calc(100% - 63px);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.SoilDateSelector .Arrow {
  padding: 20px;
  font-size: 2em;
  cursor: pointer;
}

.SoilDateSelector .Arrow.disabled {
  cursor: default;
  opacity: 0.3;
}

.SoilDateSelector .DateDisplay {
  /* width: 120px; */
  height: 100%;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  /* align-items: center; */
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background: #fcfcfc;
  border: 2px solid #e8e8e8;
  border-radius: 10px;
}

.SoilDateSelector .DateDisplay label {
  /* background: #eee; */
  /* border-bottom: 2px solid #e8e8e8; */
  height: 100%;
  width: 100%;
  text-align: center;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  /* padding: 10px 0; */
}

.SoilDateSelector .DateDisplay .Month {
  font-size: 17px;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 15px 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.SoilInput {
  width: 100%;
  height: 320px;
  /* background: #51534a; */
  box-sizing: border-box;
  padding: 0 10px 10px;
}

.SoilInput .InputContainer {
  width: 100%;
  height: calc(100% - 63px - 30px);
  display: -webkit-flex;
  display: flex;
}

.SoilInput .InputContainer .InputCol {
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 10px 10px;
}

.SoilInput .InputContainer .InputCol .PaddockList {
  height: calc(100% - 30px - 20px - 30px);
  overflow-y: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.SoilInput .InputContainer .InputCol .PaddockList .Paddock {
  background: #e8e8e8;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 2px 3px;
  /* height: 30px; */
  /* display: block;text-align: center; */
}

.SoilInput .InputContainer .InputCol .PaddockAreaDisplay {
  display: -webkit-flex;
  display: flex;
  height: 30px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

/* .SoilInput .InputContainer .InputCol .PaddockList .Row:first-child {
  border-top: 2px solid #e8e8e8;
} */

.SoilInput .InputContainer .InputCol .Row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  height: 30px;
}

.SoilInput .InputContainer .Button {
  width: 100%;
  margin: 0;
}

.SoilInput .SoilInputButtonContainer {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
}

.SoilInput .SoilInputButtonContainer .Button {
  width: 30%;
  margin: 0 5px;
  background: #78be20;
}

.SoilInput .SoilInputButtonContainer .Button:first-child {
  background: #51534a;
}

.TableDiv {
  height: calc(100% - 22px);
  width: calc(50%);
  min-height: 78px;
  margin: 10px;
  overflow-y: auto;
  border: 1px solid white;
  border-radius: 5px;
  background: #f2f2f2;
}

.SelectedTable {
  border-collapse: collapse;
  width: calc(100%);
  text-align: center;
  color: white;
  font-size: 13px;
}

tbody {
  color: #51534a;
  font-size: 13px;
}

.SelectedTable tr th {
  background: rgba(81, 83, 74, 0.8);
  font-weight: normal;
  letter-spacing: 0.7px;
  font-size: 16px;
  padding: 8px;
}

.SelectedTable tr td {
  background: #f2f2f2;
  border-top: 3px solid white;
  padding: 3px;
  font-size: 1.1em;
}

.SelectedTable tbody:nth-child(even) tr td {
  background: white;
  border-top: 3px solid #f2f2f2;
  padding: 3px;
}
.MapTableContainer {
  width: 100%;
  height: calc(100%);
  overflow-y: auto;
  float: right;
  box-sizing: border-box;
}

.MapTable {
  font-size: 14px;
  text-align: center;
  border-collapse: collapse;
  width: 100%;
  float: right;
  font-weight: bold;
}

.MapTable th {
  background: rgba(81, 83, 74, 0.8);
  color: white;
}

.MapTable tbody tr th {
  color: white;
  font-weight: bold;
  letter-spacing: 0.3px;
  margin: 2px 0px 2px 0px;
}

.MapTable tr:nth-child(odd) td {
  background: white;
  color: #51534a;
}

.MapTable tr:nth-child(even) td {
  background: white;
  color: #51534a;
}

.MapTable tr th {
  padding: 3px;
  width: 33%;
  font-weight: bold;
}

.MapTable tr td {
  padding: 4px;
  font-weight: normal;
  cursor: pointer;
}

.MapTable tr.active td {
  background: #51534a;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.MapTable tr th.MapTableHeader {
  font-size: 18px;
  padding: 3px;
  font-weight: bold;
}

.MapButtonContainer {
  display: -webkit-flex;
  display: flex;
}

.MapButtonAddContainer {
  position: relative;
}

.MapButtonAdd {
  background: #7e9294;
  border-width: 0 1px 1px 0;
  border-color: #324648;
  background: rgba(81, 83, 74, 0.8);
  border-style: solid;
  color: white;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.MapButtonAddContainer .MapButtonAdd {
  background: rgba(81, 83, 74, 0.8);
  border-width: 0 2px 2px 0;
  border-color: #51534a;
  border-style: solid;
  color: white;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 5;
}

.MapButtonAdd:hover {
  background: #51534a;
  color: white;
}

.MapButtonAddContainer .Feature {
  background: rgba(81, 83, 74, 0.6);
  border-width: 0px 2px 2px 0;
  border-color: #51534a;
  border-style: solid;
  color: white;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 4;
  transition: top 250ms;
  opacity: 0;
}

.MapButtonAddContainer.Show .MapButtonAdd {
  background: #51534a;
  color: white;
}

.MapButtonAddContainer.Show .Feature {
  opacity: 1;
}

.MapButtonAddContainer.Show .Feature:nth-child(2) {
  top: 30px;
}

.MapButtonAddContainer.Show .Feature:nth-child(3) {
  top: 60px;
}

.MapButtonAddContainer.Show .Feature:nth-child(4) {
  top: 90px;
}

.MapButtonAddContainer.Show .Feature:nth-child(5) {
  top: 120px;
}

.MapButtonAddContainer.Show .Feature:nth-child(6) {
  top: 150px;
}

.MapButtonAddContainer.Show .Feature:nth-child(7) {
  top: 180px;
}

.MapButtonAddContainer .Feature:hover {
  background: #51534a;
  color: white;
}

.MapButtonAddContainer .Feature:active {
  background: #51534a;
  color: white;
}

.MapButton {
  background: rgba(81, 83, 74, 0.8);
  border-width: 0 2px 2px 0;
  border-color: #51534a;
  border-style: solid;
  color: white;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.MapButton:hover {
  background: #51534a;
  color: white;
}

.MapButtonIcon {
  height: 15px;
  width: 15px;
  margin-left: 10px;
  float: right;
  transition: all 150ms;
}

.MapButtonAddContainer.Show .MapButtonIcon {
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.HomePageWrapper {
  width: 100vw;
  height: calc(100vh - 65px);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: #91938822;
}
.HomePageContainer {
  --width: min(min(100vw, calc(calc(100vh - 65px) / 0.6)), 16000px);
  width: var(--width);
  height: calc(var(--width) * 0.6);
  /* background-color: red; */
  box-sizing: border-box;
}
.HomePageController {
  width: var(--width);
  height: calc(var(--width) * 0.6);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
  /* background: #91938822; */
  /* box-shadow: 0 0 3px  rebeccapurple; */
}

.DrawingContainer {
  width: calc(var(--width) * 0.9);
  height: calc(var(--width) * 0.2);

  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
}

.DrawingContainer > * {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}

.IconsContainer {
  z-index: 1;
  width: calc(var(--width) * 0.9);
  height: calc(var(--width) * 0.2);

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Drawing {
  width: 100%;
  padding: 2.5% 1% 0;
  box-sizing: border-box;
  z-index: 2;
  pointer-events: none;
}

.IconsContainer .Icon {
  background: white;

  z-index: 1;
  width: calc(var(--width) * 0.2);
  height: calc(var(--width) * 0.2);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-radius: calc(var(--width) * 0.01);
  transition: all 250ms;

  box-sizing: border-box;
  cursor: pointer;
}

.IconsContainer .Icon h1 {
  font-family: neuzeit-grotesk, sans-serif;
  font-size: calc(var(--width) * 0.025);
  font-weight: 400;
  margin: 0;
  line-height: normal;
  color: #919388;
}

.IconsContainer .Icon.Grass {
  border: calc(var(--width) * 0.004) solid #78be2099;
}

.IconsContainer .Icon.Grass.Enabled h1 {
  color: #78be20;
  font-weight: 700;
}

.IconsContainer .Icon.Weighing {
  border: calc(var(--width) * 0.004) solid #f2a90099;
}

.IconsContainer .Icon.Weighing.Enabled h1 {
  color: #f2a900;
  font-weight: 700;
}

.IconsContainer .Icon.Growth {
  border: calc(var(--width) * 0.004) solid #00b2de99;
}

.IconsContainer .Icon.Environment {
  border: calc(var(--width) * 0.004) solid #00b2de99;
}

.IconsContainer .Icon.Growth.Enabled h1 {
  color: #00b2de;
  font-weight: 700;
}

.IconsContainer .Icon.Environment.Enabled h1 {
  color: #00b2de;
  font-weight: 700;
}

.Graphic {
  width: 80%;
  box-sizing: border-box;
}

.DescriptionContainer {
  font-family: neuzeit-grotesk, sans-serif;
  z-index: 1;
  width: calc(var(--width) * 0.9);
  height: calc(var(--width) * 0.3);
  margin-top: calc(var(--width) * 0.02);

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.DescriptionContainer .Icon.Description {
  width: calc(var(--width) * 0.16);
  height: 100%;
  box-sizing: border-box;
  border-radius: calc(var(--width) * 0.01);
  background-color: white;
  padding: calc(var(--width) * 0.01);
  margin: calc(var(--width) * 0.02);
  line-height: normal;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;

  -webkit-align-items: stretch;

          align-items: stretch;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.DescriptionContainer .Icon.Description h1 {
  font-size: calc(var(--width) * 0.016);
  margin: 0;
  font-weight: 600;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  line-height: calc(var(--width) * 0.035);
}

.DescriptionContainer .Icon.Description .FieldIcon {
  height: calc(var(--width) * 0.017);
}

.DescriptionContainer .Icon.Description h2 {
  font-size: calc(var(--width) * 0.015);
  margin: 0;
  font-style: italic;
  font-weight: 400;
  line-height: calc(var(--width) * 0.016);
}
.DescriptionContainer .Icon.Description ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.DescriptionContainer .Icon.Description li {
  font-size: calc(var(--width) * 0.014);
  margin: 0;
  font-weight: 400;
  line-height: calc(var(--width) * 0.016);
  letter-spacing: 0;
}

.DescriptionContainer .Icon.Grass {
  border: calc(var(--width) * 0.001) solid #78be2099;
  /* background: hsl(87, 71%, 90%); */
}

.DescriptionContainer .Icon.Weighing {
  border: calc(var(--width) * 0.001) solid #f2a90099;
  /* background: hsl(42, 100%, 92%); */
}

.DescriptionContainer .Icon.Growth {
  border: calc(var(--width) * 0.001) solid #00b2de99;
  /* background: hsl(192, 100%, 90%); */
}

.DescriptionContainer .Icon.Environment {
  border: calc(var(--width) * 0.001) solid #00b2de99;
  /* background: hsl(192, 100%, 90%); */
}

.DescriptionContainer .ButtonContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.DescriptionContainer .ButtonContainer .Button {
  width: calc(var(--width) * 0.086);
  height: calc(var(--width) * 0.03);
  border-radius: calc(var(--width) * 0.005);
  background: white;
  color: #51534a;
  font-weight: 600;
  font-size: calc(var(--width) * 0.013);
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0;
  transition: all 250ms;
}

.DescriptionContainer .ButtonContainer .Button.Grass {
  border: calc(var(--width) * 0.001) solid #78be2099;
}
.DescriptionContainer .ButtonContainer .Button.Grass:hover {
  background: #78be2099;
}
.DescriptionContainer .ButtonContainer .Button.Weighing {
  border: calc(var(--width) * 0.001) solid #f2a90099;
}
.DescriptionContainer .ButtonContainer .Button.Weighing:hover {
  background: #f2a90099;
}
.DescriptionContainer .ButtonContainer .Button.Growth {
  border: calc(var(--width) * 0.001) solid #00b2de99;
}
.DescriptionContainer .ButtonContainer .Button.Growth:hover {
  background: #00b2de99;
}
.DescriptionContainer .ButtonContainer .Button.Environment {
  border: calc(var(--width) * 0.001) solid #00b2de99;
}
.DescriptionContainer .ButtonContainer .Button.Environment:hover {
  background: #00b2de99;
}

.DescriptionContainer .ButtonContainer .Button.Full {
  width: 100%;
}

.HomePageFarmBrowser {
  font-family: neuzeit-grotesk, sans-serif;
  z-index: 1;
  width: calc(var(--width));
  height: calc(var(--width) * 0.3);
  margin-top: calc(var(--width) * 0.02);

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.HomePageFarmBrowser .BrowserIconContainer {
  width: 100%;
  height: calc(var(--width) * 0.3);
  /* padding: 5%; */
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.HomePageFarmBrowser .BrowserIconContainer .BrowserIconRow {
  width: calc(var(--width) * 0.9);
  height: calc(var(--width) * 0.3);
  /* padding: 5%; */
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.HomePageFarmBrowser .BrowserIconContainer .FarmBrowserNav {
  font-family: neuzeit-grotesk, sans-serif;
  color: #51534a;
  font-weight: 600;
  height: 100%;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(var(--width) * 0.03);
  width: calc(var(--width) * 0.05);
}

.LandingPage {
  min-height: 100vh;
  width: 100%;
  --width: min(80vw, 16000px);
  -webkit-flex-direction: column;
          flex-direction: column;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: #91938822;
  font-family: neuzeit-grotesk, sans-serif;
}

.LandingHeader {
  width: 100%;
  background: #51534a;
  -webkit-flex-direction: column;
          flex-direction: column;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.LandingHeader .Icon {
  height: min(80px, 12vw);
  grid-column: 2 / 2;
  grid-row: 1 / 1;
}

.LandingHeader .IconDiv {
  display: grid;
  grid-template: 1fr / 1fr 3fr 1fr;
  place-items: center;
  width: 100%;
}

.LandingHeader:nth-child(even) {
  background: white;
}

.LandingPageLoginContainer {
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(145, 147, 136, 0.8);
  background: rgba(145, 147, 136, 0.3);
  background: hsl(73, 6%, 41%);
  /* height: 65px; */
}

.LandingPageLoginContainer {
  grid-column: 2 / 2;
  grid-row: 1 / 1;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.LandingPageButtonContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 5px;
}

.LandingPageFlagsContainer {
  grid-column: 3 / 3;
  grid-row: 1 / 1;
  justify-self: end;
}

.LandingPageLoginContainer .Button {
  height: 45px;
  width: 225px;
  border-radius: 5px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: white;
  cursor: pointer;
  font-weight: 400;
  font-size: 28px;
  margin: 0 5px;
}
.LandingPageLoginButton {
  border: 1px solid #919388;
  background-color: #91938899;
}

.LandingPageLoginButton.LandingPageActiveButton {
  border: 1px solid #78be20;
  background-color: #77be2099;
}
.LandingPageLoginBody {
  border: 1px solid #919388;
  background-color: #91938899;
  height: 200px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
  place-items: center;
  width: 100%;
}

.LandingPageLoginBody * {
  grid-column: 2 / 2;
}

.LandingPageLoginBody label {
  color: white;
  justify-self: end;
  padding: 0 10px;
  text-align: end;
  font-size: 1.2em;
  grid-column: 1 / 1;
}

.LandingPageLoginBody h3 {
  color: white;
  justify-self: end;
  padding: 0 10px;
  text-align: end;
  font-size: 2em;
  margin: 10px;
}
.LandingPageLoginBody label.Price {
  color: white;
  justify-self: start;
  padding: 0 10px;
  margin: 0;
  text-align: start;
  font-size: 2em;
  grid-column: 3 / 3;
  font-family: roboto, sans-serif;
}
.LandingPageLoginBody label.Price.Total {
  color: white;
  justify-self: start;
  padding: 0 10px;
  margin: 0;
  text-align: start;
  font-size: 2.5em;
  grid-column: 3 / 3;
}

.LandingPageLoginBody form {
  grid-column: 1 / 4;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
  place-items: center;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.LandingPageLoginBody img {
  justify-self: start;
  grid-column: 3 / 3;
  padding: 0 10px;
  cursor: pointer;
}


.LandingPageLoginBody p {
  color: white;
  font-size: 3em;
  grid-row: 1 / 3;
}

.LandingPageLoginBody .FormInput {
  color: #51534a;
  background: #ffffffdd;
  font-size: 1.2em;
  margin: 3px;
  height: 40px;
  text-align: center;
}

.LandingPageLoginBody .Button {
  color: white;
  padding: 24px;
  border-radius: 12px;
  width: 250px;
  border: 1px solid #78be20;
  background-color: #77be20cc;
  margin: 16px 0 0;
}

.LandingPageDrawingSpacer {
  height: max(
    calc(calc(100vh - 80px - 55px - 200px - calc(var(--width) * 0.2)) / 2),
    5vw
  );
}

.LandingPageLoginContainer p.Error {
  color: #c00d1e;
  font-weight: normal;
  font-size: 24px;
  color: white;
  background: #c00d1e;
  padding: 16px;
  border-radius: 8px;
  box-sizing: border-box;
  /* width: 90%; */
  margin: 16px 0 0;
}
.LandingPageDrawingSpacer p {
  color: #c00d1e;
  font-weight: normal;
  font-size: 24px;
  color: white;
  background: #c00d1e;
  padding: 16px;
  border-radius: 8px;
  box-sizing: border-box;
  /* width: 90%; */
  margin: 16px 0 0;
}

.LandingPageScreenshot {
  width: 55vw;
}

.LandingPagePackage {
  width: 100%;
  background: hsl(73, 6%, 41%);
  /* border-radius: 2vw ; */
  /* margin: 2vw 0 3vw 0; */
  overflow: hidden;
  background: #51534a77;
  -webkit-filter: drop-shadow(1px 1px 8px #00000055);
          filter: drop-shadow(1px 1px 8px #00000055);
}
.LandingPagePackage.Grass {
  background-color: #78be2022;
  /* margin: 2vw 0 5vw; */
}
.LandingPagePackage.Weighing {
  background-color: #f2a90022;
}
.LandingPagePackage.Environment {
  background-color: #00b2de22;
}

.LandingPagePackageHeader {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 1vw 5vw 0.2vw;
  box-sizing: border-box;
  color: white;
  width: 100%;
  /* filter: drop-shadow(1px 1px 3px #00000055); */
}
.LandingPagePackageHeader h1 {
  font-size: 2vw;
}
.LandingPagePackageHeader h2 {
  font-size: 1.5vw;
  font-style: italic;
  font-weight: 400;
}
.LandingPagePackageHeader .FieldIcon {
  width: 8vw;
}

.Grass .LandingPagePackageHeader {
  background-color: #78be2077;
}

.Weighing .LandingPagePackageHeader {
  background-color: #f2a90077;
}

.Environment .LandingPagePackageHeader {
  background-color: #00b2de77;
}

.LandingPagePackageBody {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 1vw 3vw 2vw;
  box-sizing: border-box;
}
.Weighing .LandingPagePackageBody {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.LandingPagePackageBody ul {
  padding: 0 5vw;
  margin: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.LandingPagePackageBody li {
  color: #51534a;
  font-size: calc(var(--width) * 0.025);
  margin: 0;
  font-weight: 400;
  line-height: calc(var(--width) * 0.04);
  letter-spacing: 0;
}

.SellingPackagesContainer {
  display: grid;
  grid-template: 2fr / 3fr 3fr 3fr;
  place-items: center;
  width: 90%;
}

.LandingPagePackageThird {
  width: 80%;
  margin: 10%;
  box-sizing: border-box;
  background: hsl(73, 6%, 41%);
  overflow: hidden;
  background: #51534a77;
  -webkit-filter: drop-shadow(1px 1px 8px #00000055);
          filter: drop-shadow(1px 1px 8px #00000055);
  border: 0.1vw solid #51534a;
}
.LandingPagePackageThird.Grass {
  background-color: #78be2022;
  border-color: #78be20;
  /* margin: 2vw 0 5vw; */
}
.LandingPagePackageThird.Weighing {
  background-color: #f2a90022;
  border-color: #f2a900;
}
.LandingPagePackageThird.Environment {
  background-color: #00b2de22;
  border-color: #00b2de;
}

.LandingPagePackageThird .LandingPagePackageHeader {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 1vw 2vw 0.2vw;
  box-sizing: border-box;
  color: white;
  width: 100%;
  /* filter: drop-shadow(1px 1px 3px #00000055); */
}
.LandingPagePackageThird .LandingPagePackageHeader h1 {
  font-size: 2vw;
}
.LandingPagePackageThird .LandingPagePackageHeader h2 {
  font-size: 1.2vw;
  line-height: 1.2vw;
  font-style: italic;
  font-weight: 400;
}
.LandingPagePackageThird .LandingPagePackageHeader .FieldIcon {
  width: 5vw;
}

.LandingPagePackageThird.Grass .LandingPagePackageHeader {
  background-color: #78be2077;
}

.LandingPagePackageThird.Weighing .LandingPagePackageHeader {
  background-color: #f2a90077;
}

.LandingPagePackageThird.Environment .LandingPagePackageHeader {
  background-color: #00b2de77;
}

.LandingPagePackageThird .LandingPagePackageBody {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: stretch;
          align-items: stretch;
  padding: 0;
  box-sizing: border-box;
}

.LandingPagePackageThird .LandingPagePackageBody ul {
  padding: 2vw 0 1vw 3vw;
  margin: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.LandingPagePackageThird .LandingPagePackageBody li {
  color: #51534a;
  font-size: calc(var(--width) * 0.02);
  margin: 0;
  font-weight: 400;
  line-height: calc(var(--width) * 0.025);
  letter-spacing: 0;
}

.LandingPagePackageThird .LandingPageScreenshot {
  width: 100%;
}

.LandingPagePrice {
  height: 16vw;
  padding: 0;
  text-align: center;
  font-size: calc(var(--width) * 0.02);
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.LandingPagePackageThird.Grass .LandingPagePrice {
  background-color: #78be2077;
}

.LandingPagePackageThird.Weighing .LandingPagePrice {
  background-color: #f2a90077;
}

.LandingPagePackageThird.Environment .LandingPagePrice {
  background-color: #00b2de77;
}

.LandingPageFooter {
  font-family: roboto, sans-serif;
  width: 100%;
  background: #51534a;
  -webkit-filter: drop-shadow(1px 1px 8px #00000055);
          filter: drop-shadow(1px 1px 8px #00000055);
  padding: max(0.66vw, 16px) 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: white;
  font-size: max(0.66vw, 16px);
}

.LandingPagePrice h1 {
  margin: 0 0 1vw;
  /* color: white; */
  text-align: center;
  font-size: 2.2vw;
  line-height: 2.5vw;
  font-family: roboto, sans-serif;
}
.LandingPagePrice h2 {
  margin: 1vw 0 0;
  /* color: white; */
  text-align: center;
  font-size: 1.5vw;
  line-height: 1.5vw;
}

.LandingPagePackageAll {
  width: 93.3%;
  margin: 1.67% 3.3% 3.3%;
  box-sizing: border-box;
  background: hsl(73, 6%, 41%);
  overflow: hidden;
  background: #51534a22;
  -webkit-filter: drop-shadow(1px 1px 8px #00000055);
          filter: drop-shadow(1px 1px 8px #00000055);
  grid-column: 1 / 4;
  border: 0.1vw solid #51534a;
}

.LandingPagePackageAll .LandingPagePackageHeader {
  background: #51534a99;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 1vw 2vw 0.2vw;
  box-sizing: border-box;
  color: white;
  width: 100%;
}
.LandingPagePackageAll .LandingPagePackageHeader .FieldIcon {
  width: 5vw;
}

.LandingPagePackageAll .LandingPagePackageBody {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: stretch;
          align-items: stretch;
  padding: 0;
  box-sizing: border-box;
}

.LandingPagePackageAll .LandingPagePackageBody ul {
  padding: 2vw 0 2vw 3vw;
  margin: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.LandingPagePackageAll .LandingPagePackageBody li {
  color: #51534a;
  font-size: calc(var(--width) * 0.02);
  margin: 0;
  font-weight: 400;
  line-height: calc(var(--width) * 0.025);
  letter-spacing: 0;
}

.LandingPagePackageAll .LandingPageScreenshot {
  width: 100%;
}

.LandingPagePackageAll .LandingPagePrice {
  /* background-color: #51534a99; */
  color: #51534a;
  
  /* height: 8vw; */
}

.FormInput option {
  color: #51534a;
}

.LandingPage .StripeElement {
  /* background-color: white;
  height: 30px;
  font-size: 16px;
  width: calc(100%);
  padding: 6px 10px;
  border-radius: 3px;
  border: 1px solid var(--border-light);
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;
  box-sizing: border-box; */

  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(145, 147, 136, 0.8);
  border-radius: 3px;
  color: #51534a;
  background: #ffffffdd;
  font-size: 1.2em;
  margin: 3px;
  height: 40px;
  text-align: center;
  box-shadow: none;
  padding: 10px;
}

