.Header {
  background: #51534a;
  /* background: #001315; */
  background: rgba(120,190,32, 0.2);
  background: rgba(145, 147, 136, 0.7);
  /* background: rgba(24, 44, 46, 0.9); */
  background: #51534a;
  border-top: none;
  border-bottom: none;
  /* background: linear-gradient(90deg, lightsteelblue, lightgray);     */
  height: 65px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin: 0 0 20px; */
  /* padding: 10px calc(50vw - 700px) 4px; */
  /* border-bottom: 2px solid #324648;
  border-bottom: 2px solid rgba(120,190,32, 0.7);
  border-bottom: 2px solid rgba(120,190,32, 1); */
  /* border-bottom: 2px solid rgba(120,190,32, 0.2);
  border-bottom: 2px solid rgba(145, 147, 136, 1); */
  /* border-top: 3px solid #78be20; */
  box-sizing: border-box;
  color: white;
  color: #51534a;
  -webkit-box-shadow: 1px 1px 3px 0 rgba(19, 19, 19, 0.4);
  -moz-box-shadow: 1px 1px 3px 0 rgba(19, 19, 19, 0.4);
  box-shadow: 0 0 3px 1px #51534a;

  z-index: 999;
}

.Header .NavDiv {
  /* height: 44px; */
  box-sizing: border-box;
  flex-grow: 1;
  /* width: 100%; */
  /* background: rgba(120,190,32, 0.6);
  background: rgba(255, 255, 255, 0.7); */
  /* padding: 3px 3px 1px; */
  /* border-radius: 10px; */
  text-align: center;
  /* border-left: 2px solid #51534a; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* filter: invert(0.9); */
}

.Header_group {
  flex-grow: 1;
  /* width: calc(100% - 100px); */
  /* padding: 4px 40px 4px;
  box-sizing: border-box; */
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;
  /* background-color: white; */
}

.HeaderActions {
  padding: 4px 0;
  /* height: 100%; */
  display: flex;
  /* flex-direction: column;
  align-items: flex-start;
  justify-content: space-between; */
  /* padding: 0px; */
  /* background-color: white; */
}

.MenuToggle {
  width: 50px;
  height: 50px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}

.Bar {
  margin: 3px;
  height: 4px;
  width: 30px;
  background: #51534a;
  background: #51534a;
  border-radius: 2px;
  pointer-events: none;
}

.Title {
  font-size: 25px;
  /* color: #51534a; */
  white-space: nowrap;
  /* padding-left: 20px; */
  /* font-weight: bold; */
}
.Title.mobile {
  font-size: 22px;
}

.FlagDiv {
  /* width: 145px; */
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  /* background-color: white; */
}

.FlagDiv .Flag {
  margin: 2px 3px;
  width: 50px;
  height: 30px;
  object-fit: fill;
  cursor: pointer;
}

.FlagDiv .InactiveFlags {
  display: flex;
  height: 100%;

  flex-direction: column;
  justify-content: center;

}

.FlagDiv .InactiveFlags .Flag {
  width: 30px;
  height: 18px;
}

.WeighRiteLogo {
  height: 58px;
}


.HeaderLinks {
  padding: 4px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}


.Header .IconDiv {
  height: 100%;
  box-sizing: border-box;
  padding: 0px 16px;
  text-align: center;
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}


.Header .Icon {
  height: 45px;
  filter: drop-shadow(1px 1px 3px #000000a5);
}
.Header ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.HeaderTab {
  padding: 10px;
  margin: 2px 4px;
  box-sizing: border-box;
  font-size: 18px;
  letter-spacing: 0.3px;
  cursor: pointer;
  text-align: center;
  transition: all 250ms;
  color: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
}
.HeaderTab.active {
  background: rgba(0, 0, 0, 0.3);
  color: white;
  box-shadow: 0 0 0 1px rgb(255, 255, 255) inset;
}

.HeaderTab:hover {
  background: #78be20;
  background: rgba(255, 255, 255, 1);
  background: rgba(0, 0, 0, 0.2);
  color: white;
  color: #78be20;
}

.HeaderTab.active:hover {
  background: rgba(0, 0, 0, 0.5);
  color: #78be20;
  box-shadow: 0 0 0 1px #78be20 inset;
}